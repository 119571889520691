export interface Car {
    id: string;
    name: string;
    make: string;
    model:string;
    thumbnail: string;
}
export interface  Queries{

    locationId?: string | null
    make?: string | null
    model?: string | null

}
export interface Listing {
    id: string;
    name: string;
    thumbnail: string;
    model:string;
    variant: string;
}
export interface Leasing {
    downPayment: number
    leasingPrice: number
    residualValue:number,
    duration:string
    leasingType: string

}


export interface Media{
    id: string|number;
    index: number,
    file: File
}

export interface Image{
    id: string;
    url: string
}

export interface CarDetailApiResponse{
    make: string;
    model: string;
    variant: string;
    motor: string;
    kilometerPerLiter: string;
    locationId: string;
    year: number;
    mileAge: number;
    propellantType: PropellantType;
    gearType: GearType;
    carType: CarType;
    forSale: boolean,
    forLeasing: boolean,
    salePrice: number,
    leasingData?: Leasing,
    comment: string;
    hk: string;
    files?: Media[]
    images?: Image[]
}
export interface CarDetail{
    make: string;
    model: string;
    variant: string;
    motor: string;
    kilometerPerLiter: string;
    locationId: string;
    year: number;
    mileAge: number;
    propellantType: PropellantType;
    gearType: GearType;
    carType: CarType;
    forSale: boolean,
    forLeasing: boolean,
    salePrice: number,
    leasing?: Leasing,
    comment: string;
    hk: string;
    files?: Media[]
    images?: Image[]
}
export interface CreateVehicleRequest {
    make: string;
    model: string;
    variant: string;
    motor: string;
    kilometerPerLiter: string;
    locationId: string;
    year: number;
    mileAge: number;
    propellantType: PropellantType;
    gearType: GearType;
    carType: CarType;
    forSale: boolean,
    forLeasing: boolean,
    salePrice: number,
    leasing?: Leasing,
    comment: string;
    hk: string;
    files?: Media[]
}

export enum PropellantType{
    Benzin,
    Diesel,
    HybridD,
    HybridB,
    PluginHybridD,
    PluginHybridB
}
export enum GearType{
    Manuel,
    Automatic
}
export enum CarType{
    Mikro,
    StationCar,
    SUV,
    CUV,
    MPV,
    Sedan,
    Hatchback,
    Cabriolet,
    Coupe,
    Halvkombi,


}

export const carType = [
    'Micro',
    'StationCar',
    'Suv',
    'Cuv',
    'Mpv',
    'Sedan',
    'Hatchback',
    'Cabriolet',
    'Coupe'
]
export const gearType = ['Manuel', 'Automatic']
export const propellantTypes = ['Benzin',
    'Diesel',
    'HybridD',
    'HybridB',
    'PluginHybridD',
    'PluginHybridB']
export const makes = [
    'Abarth','Acura', 'Alfa Romeo', 'Alpina','Aston Martin', 'Audi','Austin','Austin Healey', 'Bentley', 'BMW', 'Bugatti', 'Buick', 'Cadillac', 'Chevrolet', 'Chrysler', 'Citroen','Corvette',
    'Dacia','Daewoo','Daihatsu','Daimler','DeLorean','DeTomaso','Dodge','DS','Durant','Excalibur','Ferrari', 'Fiat', 'Ford','Hillman','Honda','Hummer', 'Hyundai', 'Infiniti', 'Isuzu','Jaguar',
    'Jeep','Kia', 'Lamborghini', 'Land Rover', 'Lexus', 'Maserati', 'Mazda', 'Mclaren', 'Mercedes', 'Mini', 'Mitsubishi', 'Nissan', 'Pagani', 'Peugeot', 'Porsche', 'Renault', 'Rolls Royce', 'Saab', "Subaru","Suzuki", "Tata", "Tesla", "Toyota", "VW", "Volvo"]
export const years = [2026,2025,2024,2023,2022,2021,2020,2019,2018,2017,2016,2015,2014,2013,2012,2011,2010,2009,2008,2007,2006,2005,2004,2003,2002,2001,2000]
