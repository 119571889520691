import apiClient from "./apiClient";
import axios from 'axios';
import {User} from "../@types/auth";
import {CreateUserRequest, UserData, UserResponseList} from "../models/user";
import {Company} from "../models/company";
import {Car, CarDetail, CreateVehicleRequest, Queries} from "../models/car";

import qs from "qs";

export const apiLogin = (credentials: { email: string, password: string }): Promise<UserData> => {
    return apiClient.request.post<UserData>('/auth/login', credentials)
}

export const getUser = (): Promise<User> => {
    return apiClient.request.get<User>('/account')
}

export const getCompanies = (): Promise<Company[]> => {
    return apiClient.request.get<Company[]>('/companies')
}

export const createCompany = (data: Company): Promise<void> => {
    return apiClient.request.post('/companies', data)
}

export const getCompany = (id: string): Promise<Company> => {
    return apiClient.request.get<Company>('/companies/'+id);
}

export const getCompanyVehicles = (id: string, queries?: Queries): Promise<Car[]> => {
console.log(queries);
    return apiClient.request.get<Car[]>(`/companies/${id}/vehicles?${qs.stringify(queries) }`);
}

export const updateBilinfoLiveLocation = (id: string, queries?: Queries): Promise<void> => {
console.log(queries);
    return apiClient.request.post(`/companies/${id}/update-vehicle-bilinfo`, {});
}

export const getUsers = (): Promise<UserResponseList[]> => {
    return apiClient.request.get<UserResponseList[]>('/users/');
}

export const createUser = (data: CreateUserRequest): Promise<void> => {
    return apiClient.request.post('/users/', data);
}

export const deleteVehicle = (id: string, vehicleId: string): Promise<void> => {
    return apiClient.request.delete(`companies/${id}/vehicles/${vehicleId}`);
}

export const deleteVehicleImage = (id: string, vehicleId: string, imageId: string): Promise<void> => {
    return apiClient.request.delete(`companies/${id}/vehicles/${vehicleId}/images/${imageId}`);
}
export const getVehicle = (id: string, vehicleId: string): Promise<CarDetail> => {
    return apiClient.request.get<CarDetail>(`companies/${id}/vehicles/${vehicleId}`);
}

export const createCompanyVehicle = (id: string, data: CreateVehicleRequest): Promise<void> => {
    const {files, ...vehicle} = data;
    let formData = new FormData();

    files?.forEach(item => {
        if(item.file){
            formData.append('image', item.file);
        }
    })

    formData.append('vehicle', JSON.stringify(vehicle))


    return apiClient.request.post<void>('/companies/'+id+'/vehicles', formData);
}
export const updateCompanyVehicle = (id: string, vehicleId: string, data: CreateVehicleRequest): Promise<void> => {
    const {files, ...vehicle} = data;
    let formData = new FormData();

    files?.forEach(item => {
        if(item.file){
            formData.append('image', item.file);
        }
    })

    formData.append('vehicle', JSON.stringify(vehicle))


    return apiClient.request.put<void>('/companies/'+id+'/vehicles/'+vehicleId, formData);
}

