import React, {FunctionComponent, useContext, useEffect, useState} from 'react';
import PageTitle from "../../components/PageContent/PageTitle";
import PageWrapper from "../../components/PageContent/PageWrapper";
import TopToolBar from "../../components/PageContent/TopToolBar";
import BtnLink from "../../components/Buttons/BtnLink";
import SmallLoading from "../../components/Loadings/SmallLoading";
import {RemoveRedEye} from "@mui/icons-material";
import Page from "../../components/PageContent/Page";
import useLocales from "../../hooks/useLocales";
import {Car, makes, Queries} from "../../models/car";
import {deleteVehicle, getCompanyVehicles, updateBilinfoLiveLocation} from "../../services/userService";
import {AuthContext} from "../../context/AuthContext";
import {Auth0ContextType} from "../../@types/auth";
import Vehicle from "../../components/Vehicle";
import {Box, DialogActions, Grid, MenuItem, Select, Stack, Typography} from "@mui/material";
import BtnAction from "../../components/Buttons/BtnAction";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {useNavigate} from "react-router-dom";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import snackBarUtils from "../../utils/SnackBarUtils";
import {Formik} from "formik";
import BtnLoading from "../../components/Buttons/BtnLoading";
import MySelectInput from "../../components/Forms/MySelectInput";
import StackedView from "../../components/PageContent/StackedView";
import {each} from "lodash";
import {setMode} from "@tinymce/tinymce-react/lib/es2015/main/ts/Utils";

interface OwnProps {}

type Props = OwnProps;

const MyCars: FunctionComponent<Props> = (props) => {
    const { t } = useLocales();
    const navigate = useNavigate();
    const {user} = useContext(AuthContext) as Auth0ContextType;
    const [cars, setCars] = useState<Car[]>();
    const [queries, setQueries] = useState<Queries>();
    const [models, setModelss] = useState<string[]|undefined>();
    const [deleteCarConfirmation, setDeleteCarConfirmation] = useState<Car>();

    const setModels = (cars: Car[]) =>{
        let list : string[] =  [];

        cars.forEach(s => {
            if(list.indexOf(s.model) == -1){
                list.push(s.model)
            }
        })
        console.log(list);
        setModelss(list.sort());
    }
    const getVehicles = ()=>{
        getCompanyVehicles(user?.company.id!, queries).then((res)=>{
            setCars(res);
            if(queries){
                if(queries.make){
                    setModels(res);
                }else{
                    setModelss(undefined);
                }
            }else{
                setModelss(undefined);
            }
        }).catch(()=>{

        })
    }
    useEffect(() => {
        getVehicles()
    }, [queries]);

    const handleDelete = ()=>{
        if(deleteCarConfirmation){
            deleteVehicle(user!.company.id!, deleteCarConfirmation.id).then((res)=>{
                snackBarUtils.success("SLETTET KORREKT")
                setDeleteCarConfirmation(undefined);
                getVehicles();
            }).catch((err)=>{

            })
        }
    }

    return (<Page title={t("general.cars")}>
      <PageTitle title={t("general.cars")} />
      <PageWrapper>
          <TopToolBar>
              <BtnLink url={'create'} btnType="contained" color="primary" label={'OPRET'}/>
          </TopToolBar>
          <Formik initialValues={{
              locationId: 0,
              make: 0,
              model: 0,
          }} onSubmit={(values) =>{
              setQueries({
                  locationId: values.locationId != 0 ? user!.company.locations[values.locationId-1].id! : null,
                  make: values.make != 0 ? makes[values.make-1] : null,
                  model: values.model != 0 && models != undefined ? models[values.model-1] : null,
              })
          }}>
              {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                }) => (
                  <form onSubmit={handleSubmit}>
                      <Stack direction={{ lg: "row", xs: "column" }} spacing={2} sx={{ flex: 1, margin: '30px 0' }}>
                          <MySelectInput name={"locationId"} values={["Vælg alle", ...user!.company!.locations.map(s => s.name)]} />
                          <MySelectInput name={"make"} values={["Vælg alle", ...makes]} />
                          <MySelectInput name={"model"} values={[models == undefined ? "Vælg mærke først":  "Vælg alle", ...(models ? models :[])]} />
                          <BtnLoading variant="contained" type="submit" label="Søg"/>
                      </Stack>
                  </form>
              )}
          </Formik>

          {!cars && <SmallLoading />}
          <Grid container  spacing={4}>
          {cars && cars.length > 0 && cars.map((vehicle)=>
              <Grid item lg={2} md={4} xs={6} key={vehicle.id}>
                  <Vehicle.Wrapper>
                      <Vehicle.ImageWrapper>
                          <img src={vehicle.thumbnail} alt={vehicle.name}/>
                      </Vehicle.ImageWrapper>
                      <Vehicle.ContentWrapper>
                          <Typography variant="h4" component="h3">{vehicle.name}</Typography>
                      </Vehicle.ContentWrapper>
                      <Vehicle.ButtonWrapper>
                          <BtnAction onClick={() => {
                              navigate(`${vehicle.id}`)
                          }} variant="contained" color="info" size="small" fullWidth Icon={<RemoveRedEye/>} label={t('general.edit')}/>
                          <BtnAction onClick={() => setDeleteCarConfirmation(vehicle)} variant="contained" color="error" size="small" fullWidth  Icon={< DeleteForeverIcon/>}
                                     label={t('general.delete')}/>
                      </Vehicle.ButtonWrapper>
                  </Vehicle.Wrapper>
              </Grid>
          )}
          </Grid>

          {deleteCarConfirmation && <ConfirmationDialog
              danger
            proceed={handleDelete}
              close={()=> setDeleteCarConfirmation(undefined)}
              message={"ER DU SIKKER PÅ DU VIL SLETTE OVENSTÅENDE BIL?"} openDialog={deleteCarConfirmation != undefined}
                                                        title={`SLET ${deleteCarConfirmation.name}`} /> }
      </PageWrapper>
  </Page>);
};

export default MyCars;
