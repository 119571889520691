import {Navigate, useRoutes} from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import Page404 from "../pages/Page404";
import AllUsers from "pages/users/AllUsers";
import CompaniesPage from "pages/companies/CompaniesPage";
import DashboardLayout from "../layouts/dashboard";
import AuthGuard from "../guards/AuthGuard";
import CompanyDetails from "../pages/companies/CompanyDetails";
import LoginPage from "../pages/LoginPage";
import CreateCompanyPage from "../pages/companies/CreateCompanyPage";
import CorrectRoute from "../features/CorrectRoute";
import CreateUserPage from "../pages/users/CreateUserPage";
import MyCars from "../pages/users/MyCars";
import CreateCarPage from "../pages/users/CreateCarPage";
import EditCarPage from "../pages/users/EditCarPage";
import MyCompany from "../pages/users/MyCompany";


const Routes: React.FC = (): JSX.Element => {


    const RoutesList = [
        {path: "", element: <AuthGuard><CorrectRoute /></AuthGuard>},
        {path: "login", element: <LoginPage/>},
        {
            path: "/admin",
            element: <AuthGuard><DashboardLayout/></AuthGuard>,
            children: [
                {path: "", element: <Navigate to={"/admin/dashboard"}/>,},
                {path: "dashboard", element: <Dashboard/>,},
                {path: "companies", element: <CompaniesPage/>},
                {path: "companies/create", element: <CreateCompanyPage/>,},
                {path: "companies/:id", element: <CompanyDetails/>},
                {path: "users", element: <AllUsers/>},
                {path: "users/create", element: <CreateUserPage/>},
            ]
        },
        {

            path: "/account",
            element: <AuthGuard><DashboardLayout/></AuthGuard>,
            children: [
                {path: "", element: <Dashboard/>},
                {path: "cars", element: <MyCars/>},
                {path: "cars/create", element: <CreateCarPage/>},
                {path: "cars/:carId", element: <EditCarPage />},
                {path: "my-company", element: <MyCompany />},
            ]
        },

        {path: "404", element: <Page404/>},
        {path: "404", element: <Page404/>},
        {path: "*", element: <Navigate to="/404" replace={true}/>}]

    const routing = useRoutes(RoutesList);

    return <>{routing}</>;

};
export default Routes;
