import React, {FunctionComponent, useContext} from 'react';
import PageTitle from "../../components/PageContent/PageTitle";
import Page from "../../components/PageContent/Page";
import useLocales from "../../hooks/useLocales";
import {useNavigate} from "react-router-dom";
import {AuthContext} from "../../context/AuthContext";
import {Auth0ContextType} from "../../@types/auth";
import {Typography} from "@mui/material";
import BtnAction from "../../components/Buttons/BtnAction";
import {updateBilinfoLiveLocation} from "../../services/userService";
import snackBarUtils from "../../utils/SnackBarUtils";
import {Box} from "@mui/system";

interface OwnProps {}

type Props = OwnProps;

const MyCompany: FunctionComponent<Props> = (props) => {
  const {t} = useLocales();
    const navigate = useNavigate();
    const {user} = useContext(AuthContext) as Auth0ContextType;
  return (<Page title={t("general.company")}>
      <PageTitle title={user!.company.companyName} />

      <Box sx={{textAlign: "center", marginTop: '50px'}}>
          {user!.company.locations && user!.company.locations.map((item, index)=> (
              <Typography key={index} variant="subtitle2" sx={{margin: '20px 0'}} component="h3">{item.name}</Typography>
          ))}
      <Typography variant="h5" component="h2" sx={{marginBottom: '20px'}}>Brug nedstående knap, hvis du vil hente alle biler fra bilbasen efter du har slettet dem</Typography>
      <BtnAction variant="contained" label={"OPDATER LIVE LAGER BILER FRA BILINFO"}
                 onClick={()=>{
                     updateBilinfoLiveLocation(user!.company.id).then((res)=>{
              snackBarUtils.success("VI ER IGANG...")
          })
      }}/>
      </Box>
  </Page>);
};

export default MyCompany;
